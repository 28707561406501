<template>
  <CommonPages id="home" classs="home">
    <div v-if="selectImgInfo" class="img-descript-container pc_padding">
      <div class="img-large-row">
        <img :src="selectImgInfo.regularImageUrl" alt="">
      </div>
      <div class="img-descript-main">
        <div class="img-descript-row">
          <div class="img-poster-row">
            <div class="img-poster-avatar-row">
              <img :src="selectImgInfo.userProfileSmallUrl" alt="">
              <div class="img-poster-name">
                {{ selectImgInfo.userName }}
              </div>
            </div>
            <div class="img-poster-like-row">
              <div class="imgposter cursor" :class="{ 'imgposter-like': likeStatus === 'like' }"
                @click="handleLike" />
            </div>
          </div>
          <div class="img-descript-content">
            {{ selectImgInfo.imgAltDesc }}
          </div>
        </div>
        <div class="img-tag-relate-row">
          <div class="tag-item cursor">
            {{ selectImgInfo.tags }}
          </div>
        </div>
      </div>
    </div>

    <AdComponent v-if="selectImgInfo && adsensConfig?.[adsType]" ref="ads-detail-1"
      class="m-b-40 content pc_padding" :ads="adsensConfig?.[adsType]" :showDebug="showDebug" />

    <section class="content pc_padding">
      <div v-if="!imgid" class="tag-row">
        <div v-for="(tag,i) in tagList" :key="i" :class="{'active':activeTag == tag}"
          class="tag-item cursor" @click="seclecTag(tag)">
          {{ tag }}
        </div>
      </div>

      <div v-if="selectImgInfo" class="relate-title">
        Related images
      </div>

      <template v-if="deviceType == 'mobile'">
        <template>
          <div v-for="(pos, i) in adPosConfig.homeinsertCutPos" :key="i">
            <div class="container">
              <!-- 左侧图片列表 -->
              <div v-if="images&& images.length>0" ref="leftColumn" class="left-column">
                <template v-for="(item, index) in images.slice(pos[0], (pos[0] + pos[1]))">
                  <div :key="index" class="left-item" @click="choosePic(item.id)">
                    <img :src="item.thumbImageUrl" :alt="item.imgAltDesc"
                      @load="setRightColumnHeight(i)">
                  </div>
                </template>
              </div>

              <!-- 右侧图片列表，动态控制显示数量并裁剪 -->
              <div v-if="images&& images.length>0" class="right-column"
                :style="{ maxHeight: rightColumnHeights[i] + 'px', overflow: 'hidden' }">
                <template v-for="(item, index) in getRightImages(pos[0] + pos[1], pos[1])">
                  <div :key="index" class="right-item" @click="choosePic(item.id)">
                    <img :src="item.thumbImageUrl" :alt="item.imgAltDesc">
                  </div>
                </template>
              </div>
            </div>

            <!-- 广告组件 -->
            <div class="ad-width-100" style="margin-top: 20px; margin-bottom: 20px;">
              <AdComponent v-if="isObjectNotEmpty(adsensConfig) && adsensConfig?.[adsType]"
                :ref="`ads-insert-${i}`" :ads="adsensConfig?.[adsType][i]" :showDebug="showDebug" />
            </div>
          </div>
        </template>
        <!-- 最后展示剩余的 masonry 项 -->
        <masonry :cols="{ default: 2, 1200: 4, 800: 2 }" class="waterfall-container">
          <template v-for="(item, index) in optimizedImages">
            <div v-if="!selectImgInfo || selectImgInfo && item.id != selectImgInfo.id" :key="index"
              class="waterfall-item" @mouseenter="currentHover = index"
              @mouseleave="currentHover = null" @click="choosePic(item.id)">
              <img :src="item.thumbImageUrl" :alt="item.imgAltDesc">
              <div v-show="currentHover == index" v-if="item.imgAltDesc" class="shadow-txt-row">
                <div class="thumb-contnet">
                  {{ item.imgAltDesc }}
                </div>
              </div>
            </div>
          </template>
        </masonry>
      </template>

      <template v-if="deviceType != 'mobile'">
        <masonry :cols="{ default: 4, 1200: 4, 700: 3, 500: 2 }" class="waterfall-container">
          <template v-for="(item, index) in images">
            <div v-if="!selectImgInfo || selectImgInfo && item.id != selectImgInfo.id" :key="index"
              class="waterfall-item" @mouseenter="currentHover = index"
              @mouseleave="currentHover = null" @click="choosePic(item.id)">
              <img :src="item.thumbImageUrl" :alt="item.imgAltDesc">
              <div v-show="currentHover == index" v-if="item.imgAltDesc" class="shadow-txt-row">
                <div class="thumb-contnet">
                  {{ item.imgAltDesc }}
                </div>
              </div>
            </div>
            <div v-if="shouldInsertAd(index) !== -1 " :key="`ad_${index}`"
              class="ad-inside-masonry ad-pos-row ads-display-content">
              <div class="ads-item ">
                <ins
                  v-if="!selectImgInfo && isObjectNotEmpty(adsensConfig) && adsensConfig?.[adsType]"
                  ref="ads" v-bind="adsensConfig?.[adsType][shouldInsertAd(index)]" />
                <ins
                  v-if="selectImgInfo && isObjectNotEmpty(adsensConfig) && adsensConfig?.[adsType]"
                  ref="ads" v-bind="adsensConfig?.[adsType][shouldDetailInsertAd(index)]" />
                <div v-if="showDebug" class="ad-msg">
                  {{ ads }}
                </div>
              </div>
            </div>
          </template>
        </masonry>
      </template>
    </section>
  </CommonPages>
</template>

<script>
import common from '@/mixin/common.js';
import { mapGetters } from 'vuex';
import AdComponent from '@/pages/components/AdComponent.vue'
import CommonPages from '@/pages/components/pc_common.vue'

import '@/css/common.scss';
import '@/css/blog_base.scss';

export default {
  name: 'Home',
  components: {
    AdComponent,
    CommonPages,

  },
  mixins: [common],
  props: {
    adsType: {
      type: String,
      default: 'home_insert'
    }
  },
  data () {
    return {
      images: [], // 初始数据
      page: 1,   // 当前页数
      isLoading: false, // 加载状态

      grabDataFromBack: false,
      getTagFromBack: false,

      tagList: null,
      activeTag: null,
      currentHover: null, // 用于跟踪当前 hover 的索引

      likeStatus: null, // 'like' or 'unlike'

      selectImgInfo: null,
      imgid: null,
      rightColumnHeights: {} // 存储每个右侧列的最大高度
    };
  },
  metaInfo () {
    return {
      title: this.imgid ? `Beautiful ${this.domainConfig['iamkeywords']} | ${this.domainConfig["IAmURL"]}` : `High-Quality 4K ${this.domainConfig['iamkeywords']}s | ${this.domainConfig["IAmURL"]}`,
      meta: [
        {
          name: 'description',
          content: this.selectImgInfo ? this.selectImgInfo.imgAltDesc : `Discover a stunning collection of high-quality 4K ${this.domainConfig['iamkeywords']} at ${this.domainConfig["IAmURL"]}. Browse, download, and set beautiful ultra HD backgrounds for your desktop, mobile, or tablet. Transform your screen with our premium ${this.domainConfig['iamkeywords']} today.`,
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "adsensConfig",
      'adPosConfig',
      "domainConfig"
    ]),
    initialItems () {
      return this.$store.state.initialData || [];
    },
    tagItems () {
      return this.$store.state.tagData || [];
    },
    optimizedImages () {
      const lastCutPos = this.adPosConfig.homeinsertCutPos[this.adPosConfig.homeinsertCutPos.length - 1];
      const sliceStart = lastCutPos[0] + (2 * lastCutPos[1] + 1);
      return this.images.slice(sliceStart);
    },
  },
  created () {
    // 初始化服务端数据
    if (this.initialItems && Object.keys(this.initialItems).length > 0) {
      this.images = this.initialItems.photos;
      this.hasNext = this.initialItems.hasNext;
      this.grabDataFromBack = true
      this.page = 2
    }
    if (this.tagItems && Object.keys(this.tagItems).length > 0) {
      this.tagList = this.tagItems;
      this.getTagFromBack = true
    }
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
    this.loadAdSenseScript();  // 重新加载广告脚本
    if (typeof window !== 'undefined') {
      this.initAdTracking();
    }
    if (!this.getTagFromBack) this.getTagList()
    const { tag, imgid } = this.$route.params;
    console.log(imgid);
    this.imgid = imgid
    console.log(`tag: ${tag}`);
    if (tag) {
      this.$emit('updateMeta', tag); // 触发父组件更新 Meta 的事件
      this.activeTag = tag
    }
    if (imgid) {
      this.getImgInfo(imgid)
    }

    if (!this.grabDataFromBack) await this.getImages() //服务端加载了
    if (this.deviceType == 'mobile') this.setRightColumnHeight();
    window.addEventListener('resize', this.setRightColumnHeight);

    this.onScroll = this.debounce(this.onScroll.bind(this), 300);// 使用防抖包装滚动事件
    window.addEventListener('scroll', this.onScroll);// 监听滚动事件
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
    // 组件销毁时移除滚动事件监听
    window.removeEventListener('scroll', this.onScroll);
    if (this.checkInterval) {
      clearInterval(this.checkInterval);
    }
    if (this.visibilityChangeHandler) {
      document.removeEventListener('visibilitychange', this.visibilityChangeHandler);
    }
  },
  methods: {
    isObjectNotEmpty (obj) {
      return obj && Object.keys(obj).length > 0;
    },
    //客户端交互
    initAdTracking () {
      this.activeIframe = null; // 存储最近被点击的 iframe
      this.iframes = new Map(); // 存储已添加的 iframe

      // 监听 iframe 的插入
      this.watchForIframeInsertion();

      // 设置页面可见性变化的处理
      this.setupVisibilityTracking();
    },
    watchForIframeInsertion () {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          mutation.addedNodes.forEach((node) => {
            if (node.tagName === 'IFRAME' && node.closest('ins.adsbygoogle')) {
              this.addIframe(node);
            }
          });
        });
      });

      observer.observe(document.body, { childList: true, subtree: true });
    },
    addIframe (iframeElement) {
      if (this.iframes.has(iframeElement)) {
        return; // 已经添加过，直接返回
      }

      this.iframes.set(iframeElement, {
        hasTracked: false,
        adSlot: iframeElement.closest('ins.adsbygoogle')?.dataset.adSlot || null,
      });

      // 如果还没有启动定时器，启动检查点击的定时器
      if (!this.checkInterval) {
        this.checkInterval = setInterval(this.checkIframeClick, 200);
      }
    },
    checkIframeClick () {
      const activeElement = document.activeElement;
      if (activeElement && this.iframes.has(activeElement)) {
        const iframeData = this.iframes.get(activeElement);
        if (!iframeData.hasTracked) {
          this.handleIframeClick(activeElement);
          iframeData.hasTracked = true;
          this.activeIframe = { element: activeElement, ...iframeData };
        }
      }
    },
    handleIframeClick (iframeElement) {
      console.log('Iframe 点击触发了!', iframeElement);
      const adContainer = iframeElement.closest('ins.adsbygoogle');

      if (adContainer) {
        // 获取父级 ins 元素的边界矩形
        const insRect = adContainer.getBoundingClientRect();

        // 计算父级 ins 元素相对于页面左上角的位置和高度
        const transformData = {
          insX: insRect.left,
          insY: insRect.top,
          insHeight: insRect.height,
          windowWidth: window.innerWidth,
          windowHeight: window.innerHeight,
        };

        // 调用核心功能
        this.$adClick(JSON.stringify(transformData));
        this.trackEvent('ad_iframe_click', 'click', {
          'data-ad-slot': adContainer.dataset.adSlot,
        });
      }
    },
    setupVisibilityTracking () {
      this.visibilityChangeHandler = () => {
        if (document.visibilityState === 'visible') {
          console.log('页面激活状态，返回页面');
          this.activeIframe = null; // 重置最近被点击的 iframe 记录
        } else {
          console.log('页面非激活状态');
          if (this.activeIframe) {
            // 上报逻辑
            this.trackEvent('ad_iframe_click_leave', 'click', {
              'data-ad-slot': this.activeIframe.adSlot,
            });
          }
        }
      };

      document.addEventListener('visibilitychange', this.visibilityChangeHandler);
    },
    trackEvent (eventName, eventValue, dataAttributes) {
      // 事件追踪逻辑
      console.log(`${eventName} with value ${eventValue} on`, dataAttributes);

      // 调用核心功能
      if (typeof this.$eventrack === 'function') {
        this.$eventrack(eventName, eventValue, {
          ...dataAttributes,
        });
      }
    },
    //end 客户端交互
    setRightColumnHeight (index) {
      this.$nextTick(() => {
        const leftColumn = this.$refs.leftColumn[index];
        if (leftColumn) {
          // 获取每个 left-item 的高度，包括 margin
          const leftItems = leftColumn.querySelectorAll('.left-item');
          let totalHeight = 0;

          leftItems.forEach(item => {
            const itemHeight = item.getBoundingClientRect().height;
            const style = window.getComputedStyle(item);
            const marginTop = parseFloat(style.marginTop);
            const marginBottom = parseFloat(style.marginBottom);

            // 高度包含上下 margin
            totalHeight += itemHeight + marginTop + marginBottom;
          });

          this.$set(this.rightColumnHeights, index, totalHeight);
        }
      });
    },

    getRightImages (start, count) {
      // 根据左侧高度动态返回右侧需要显示的图片数量
      // const leftCount = count
      // console.log(leftCount, 2 * leftCount + 1);
      // console.log(`right pic start:${start},next start from:${start + (2 * count + 1)}`);
      return this.images.slice(start, start + (2 * count + 1)); // 动态取右侧图片范围
    },
    loadLikeStatus () {
      // 从localStorage中读取当前图片的点赞状态
      const status = localStorage.getItem(`img_${this.selectImgInfo.id}_status`);
      if (status) {
        this.likeStatus = status;
      }
    },
    handleLike () {
      if (this.likeStatus !== 'like') {
        this.likeStatus = 'like'; // 改为点赞状态
        localStorage.setItem(`img_${this.selectImgInfo.id}_status`, 'like');
      } else {
        // 如果已经是点赞状态，再次点击恢复为点踩
        this.likeStatus = 'unlike';
        localStorage.setItem(`img_${this.selectImgInfo.id}_status`, 'unlike');
      }
    },
    //shuju
    async getImgInfo (id) {
      try {
        const res = await this.$global.server.getImgIdInfo({ id, type: this.domainConfig['IAMTYPE'] })
        console.log(res)
        this.selectImgInfo = res.data
        this.loadLikeStatus();

      } catch (err) {
        console.log(err)
      }
    },
    async getTagList () {
      try {
        const res = await this.$global.server.getTagList({ type: this.domainConfig['IAMTYPE'] })
        console.log(res)
        this.tagList = res.data
      } catch (err) {
        console.log(err)
      }
    },
    async getImages () {
      try {
        const params = {
          pageNum: this.page,
          pageSize: 30,
          type: this.domainConfig['IAMTYPE']
        };

        // 只有当 this.tag 不为 null 时，才添加 tag 字段
        if (this.activeTag !== null) {
          params.tag = this.activeTag;
        }

        const res = await this.$global.server.getImages(params);
        console.log(res)
        console.log('iiiiiiiiii');
        if (res.code == 200) {
          let data = res.data.photos
          this.images.push(...data)
          this.hasNext = res.data.hasNext
          this.page++;
        }
      } catch (err) {
        console.log(err)
      }
    },
    seclecTag (tag) {

      const currentParams = this.$router.currentRoute.params; // 当前路由的 params
      const currentQuery = this.$route.query; // 当前路由的 query

      const channel = currentParams.channel ? `/${currentParams.channel}` : ''; // 拼接 channel path
      const queryString = new URLSearchParams(currentQuery).toString(); // 转换 query 对象为字符串
      const fullQueryString = queryString ? `?${queryString}` : ''; // 如果有查询参数，拼接为 ?xxx=yyy 格式

      window.location.href = `${channel}/tag/${tag}${fullQueryString}`;

      // window.location.href = `/tag/${tag}`
    },
    choosePic (id) {

      const currentParams = this.$router.currentRoute.params; // 当前路由的 params
      const currentQuery = this.$route.query; // 当前路由的 query

      const channel = currentParams.channel ? `/${currentParams.channel}` : ''; // 拼接 channel path
      const queryString = new URLSearchParams(currentQuery).toString(); // 转换 query 对象为字符串
      const fullQueryString = queryString ? `?${queryString}` : ''; // 如果有查询参数，拼接为 ?xxx=yyy 格式

      window.location.href = `${channel}/${id}${fullQueryString}`;

      // window.location.href = `/${id}`
    },

    // 防抖函数
    debounce (func, delay) {
      let timeout;
      return function (...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          func.apply(this, args);
        }, delay);
      };
    },
    onScroll () {
      // 获取文档的总高度
      const documentHeight = document.documentElement.scrollHeight;
      // 当前滚动的高度
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      // 视口的高度
      const windowHeight = window.innerHeight;

      // 当滚动距离接近底部2000px时触发
      if (scrollTop + windowHeight >= documentHeight - 2000) {
        console.log('加载');
        this.getImages();
      }
    },
    //广告相关start
    shouldInsertAd (index) {
      // 判断是否应该插入广告的位置
      const position = index + 1;
      const adIndex = this.adPosConfig.home.indexOf(position);

      /// 返回广告在 homeAD 中的索引，如果不在配置中则返回 -1
      return adIndex !== -1 ? adIndex : -1;
    },
    shouldDetailInsertAd (index) {
      // 判断是否应该插入广告的位置
      const position = index + 1;
      const adIndex = this.adPosConfig.detail.indexOf(position);

      /// 返回广告在 homeAD 中的索引，如果不在配置中则返回 -1
      return adIndex !== -1 ? adIndex : -1;
    },
    async loadAdSenseScript () {
      // 其他需要在初次加载时执行的操作
      // 先检查广告是否已经加载
      if (window.adsbygoogle && window.adsbygoogle.loaded) { // 检查广告是否已经加载
        console.log("Adsense script already loaded.");
        this.$eventrack('adscript_loaded', 'expose');
        this.observeAds()
        return; // 如果已加载，直接返回
      }
      await this.loadScriptConditionally();
    },
    loadScriptConditionally () {
      console.log(this.adsensConfig);
      // 判断广告脚本URL是否存在
      if (!this.adsensConfig?.scriptUrl) {
        console.log('广告脚本的URL不存在,终止加载广告外链');
        this.$eventrack('no_adscript_config', 'expose');
        return;
      }
      // 检查该脚本是否已经被添加
      const existingScript = document.querySelector(`script[src="${this.adsensConfig?.scriptUrl}"]`);
      if (existingScript) {
        this.$eventrack('adscript_exist', 'expose');
        console.log('脚本已存在，无需重新添加');
        this.observeAds('ads-'); // 监听广告元素进入可视区域
        return;
      }

      console.log('准备插入脚本');
      const script = document.createElement('script');
      script.src = this.adsensConfig?.scriptUrl;
      script.crossOrigin = 'anonymous';
      script.async = true;

      const header = document.getElementsByTagName('head')[0];
      header.appendChild(script);

      this.$eventrack('adscript_add_success', 'expose');
      // script.onload = this.loadAdWithDelay.bind(this); // 使用 bind 确保 this 指向正确
      script.onload = this.observeAds.bind(this); // 使用 bind 确保 this 指向正确
      console.log('脚本插入完成,加载完成,执行加载插入广告及监听操作');

    },
    observeAds () {
      const adsElements = Object.entries(this.$refs)
        .filter(([key]) => key.startsWith('ads-'))
        .flatMap(([, ref]) => ref);

      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            // 当广告元素进入可视区域时加载广告
            console.log('Ad element is in view:', entry.target);
            this.loadAdWithDelay(entry.target);
            observer.unobserve(entry.target); // 加载后停止观察该广告元素
          }
        });
      });
      console.log(adsElements);

      adsElements.forEach(ad => {
        const adElement = ad instanceof HTMLElement ? ad : ad.$el;  // 如果是 Vue 实例，获取它的 $el
        if (adElement) {
          observer.observe(adElement); // 开始监听广告元素是否进入可视区域
          console.log('Observing ad element:', adElement);
        }
      });
    },
    loadAdWithDelay (adElement) {
      // console.log('7897988979879');
      // console.log(adElement);
      setTimeout(() => {
        this.displayAd(adElement);
      }, 1500);
    },

    async displayAd (adElement) {
      await this.$nextTick();

      if (!window.adsbygoogle || !window.adsbygoogle.loaded) {
        console.log("Adsense script not loaded yet, delaying ad display.");
        setTimeout(() => this.displayAd(adElement), 500); // 延迟再次尝试
        return;
      }

      console.log('Ad element is ready for push:', adElement);
      if (adElement.offsetWidth > 0) {
        console.log('ready to push');
        (adsbygoogle = window.adsbygoogle || []).push({});
      } else {
        console.log('Ad element has no size, skipping.');
      }
    },
    //广告相关end

    //ciyi

    goto (name) {
      this.$router.push({ name: name });
    },

  }
};
</script>
